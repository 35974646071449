<template>
  <div class="grid grid-cols-2 m:grid-cols-1 gap-12">
    <template v-for="wochentag in weekdays">
      <div class="" v-if="getTrainingsByWeekday(wochentag).length > 0">
        <h2 class="text-2xl">{{ wochentag }}</h2>

        <div class="grid grid-cols-1 gap-4">
          <div
            v-for="training in getTrainingsByWeekday(wochentag)"
            class="block bg-background text-primary-text shadow"
          >
            <div class="p-4">
              <h3 class="leading-tight mb-4">{{ training.title }}</h3>
              <p class="mb-2">{{ training.wochentag }}, {{ training.startzeit }} - {{ training.endzeit }}</p>
              <p>
                Trainer:<br>
                <template v-for="trainer in training.trainer">
                  <a :href="'tel:' + trainer.telefon" v-if="trainer.telefon" class="text-accent" :title="trainer.telefon">{{ trainer.name }}<i class="fa-solid fa-phone-flip ml-2"></i></a>
                  <div v-else>{{ trainer.name }}</div>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>

    </template>

  </div>
</template>

<script setup lang="ts">
import { all } from "axios";
import {ref, onMounted} from "vue";

const {trainings} = defineProps({
  trainings: {
    type: Object,
    required: true
  }
})

const activeTeam = ref([]);
const allFilteredTeam = ref([]);

const weekdays = ref([
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag"
]);

onMounted(() => {

});
console.log(trainings);

const getTrainingsByWeekday = (wochentag: string) => {
  return trainings.filter((training: any) => training.wochentag === wochentag).sort((a: any, b: any) => {
    return a.startzeit.localeCompare(b.startzeit);
  });
}

</script>

<style scoped>

</style>