<template>
    <div class="flex gap-1 mb-4">
      <div class="text-f5 mb-2 px-2 py-1 text-background rounded-lg inline-block cursor-pointer"
        :class="{ 'bg-accent': chosenCategory === '', 'bg-secondary-text': chosenCategory !== '' }"
        @click="chooseCategory('')"
      >
        Alle
      </div>
      <div v-for="category in categories" 
        class="text-f5 mb-2 px-2 py-1  text-background rounded-lg inline-block cursor-pointer"
        :class="{ 'bg-accent': chosenCategory === category, 'bg-secondary-text': chosenCategory !== category }"
        @click="chooseCategory(category)"
      >
        {{ category }}
      </div>
    </div>

    <div class="grid grid-cols-3 m:grid-cols-2 s:grid-cols-1 gap-8">

      <div
        v-for="teamEntry in activeTeam"
        class="block bg-background text-primary-text shadow"
      >
        <div class="w-full pt-[100%] overflow-hidden relative">
            <div class="bg-cover bg-center absolute top-0 left-0 w-full h-full"
                :style="{ 'background-image': 'url(' + teamEntry.imageUrl + ')' }"
            ></div>
        </div>
        <div class="p-4">
            <div v-for="category in teamEntry.categories" class="text-f5 mb-2 px-2 py-1 bg-secondary-text text-background rounded-lg inline-block mr-1">
              {{ category }}
            </div>
            <h3 class="leading-tight mb-4">{{ teamEntry.title }}</h3>
            <p>{{ teamEntry.funktion }}</p>
        </div>
      </div>
  </div>
  <div class="flex justify-center mt-8" v-if="allFilteredTeam.length > activeTeam.length">
    <button class="btn-primary" @click="showMore()">Mehr anzeigen</button>
  </div>
</template>

<script setup lang="ts">
import { all } from "axios";
import {ref, onMounted} from "vue";

const {team, limit} = defineProps({
  team: {
    type: Object,
    required: true
  },
  limit: {
    type: Number,
    required: true
  }
})

const activeTeam = ref([]);
const allFilteredTeam = ref([]);
const categories = ref([]);
const chosenCategory = ref('');

const chooseCategory = (category: string) => {
  chosenCategory.value = category;

  if(category === '') {
    allFilteredTeam.value = team;
  }
  else {
    allFilteredTeam.value = team.filter(teamEntry => {
      let hasCategory = false;
      teamEntry.categories.forEach((teamCategory: string) => {
        if(teamCategory === category) {
          hasCategory = true;
        }
      });
      return hasCategory;
    });
  }

  activeTeam.value = allFilteredTeam.value.slice(0, limit);
}

const showMore = () => {
  activeTeam.value = allFilteredTeam.value.slice(0, (activeTeam.value.length + limit));
}

onMounted(() => {
  activeTeam.value = team.slice(0, limit);
  
  allFilteredTeam.value = team;

  team.forEach((teamEntry: any) => {
    teamEntry.categories.forEach((category: string) => {
      if (!categories.value.includes(category)) {
        categories.value.push(category);
      }
    });
  });
});

console.log(team);

</script>

<style scoped>

</style>