<template>
    <div class="flex gap-1 mb-4">
      <div class="text-f5 mb-2 px-2 py-1 text-background rounded-lg inline-block cursor-pointer"
        :class="{ 'bg-accent': chosenCategory === '', 'bg-secondary-text': chosenCategory !== '' }"
        @click="chooseCategory('')"
      >
        Alle
      </div>
      <div v-for="category in categories" 
        class="text-f5 mb-2 px-2 py-1  text-background rounded-lg inline-block cursor-pointer"
        :class="{ 'bg-accent': chosenCategory === category, 'bg-secondary-text': chosenCategory !== category }"
        @click="chooseCategory(category)"
      >
        {{ category }}
      </div>
    </div>

    <div class="grid grid-cols-3 m:grid-cols-2 s:grid-cols-1 gap-8">

      <a 
        v-for="newsEntry in activeNews"
        :href="newsEntry.url"
        class="block bg-background text-primary-text shadow perspective-card"
      >
        <div class="w-full pt-[100%] overflow-hidden relative">
            <div class="bg-cover bg-center absolute top-0 left-0 w-full h-full"
                :style="{ 'background-image': 'url(' + newsEntry.imageUrl + ')' }"
            ></div>
        </div>
        <div class="p-4">
            <div v-if="newsEntry.category" class="text-f5 mb-2 px-2 py-1 bg-secondary-text text-background rounded-lg inline-block">{{ newsEntry.category }}</div>
            <h3 class="leading-tight mb-4">{{ newsEntry.title }}</h3>
            <p>{{ newsEntry.text }}</p>
        </div>
      </a>
  </div>
  <div class="flex justify-center mt-8" v-if="allFilteredNews.length > activeNews.length">
    <button class="btn-primary" @click="showMore()">Mehr anzeigen</button>
  </div>
</template>

<script setup lang="ts">
import { all } from "axios";
import {ref, onMounted} from "vue";

const {news, limit} = defineProps({
  news: {
    type: Object,
    required: true
  },
  limit: {
    type: Number,
    required: true
  }
})

const activeNews = ref([]);
const allFilteredNews = ref([]);
const categories = ref([]);
const chosenCategory = ref('');

const chooseCategory = (category: string) => {
  chosenCategory.value = category;

  if(category === '') {
    allFilteredNews.value = news;
  }
  else {
    allFilteredNews.value = news.filter(newsEntry => newsEntry.category === category);
  }
  activeNews.value = allFilteredNews.value.slice(0, limit);
}

const showMore = () => {
  console.log(activeNews.value);
  console.log(allFilteredNews.value);

  activeNews.value = allFilteredNews.value.slice(0, activeNews.value.length + limit);
}

onMounted(() => {
  activeNews.value = news.slice(0, limit);
  
  allFilteredNews.value = news;

  news.forEach((newsEntry: any) => {
    if (newsEntry.category && !categories.value.includes(newsEntry.category)) {
      categories.value.push(newsEntry.category);
    }
  });
});

console.log(news);

</script>

<style scoped>

</style>